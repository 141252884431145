$icon-font-path: '../fonts/';

// Basic Setup
$body-bg: #FFFFFF;
$text-color: #4A4A4A;
$highlight-color: #00FF00;

$font-size-base: 14px;
$base-font-spacing: 2px;
$line-height-base: 1.428571429; // 20/14;

$font-family-sans-serif: "Frutiger LT W01_45 Ligh1475730", sans-serif;

$font-normal: $font-family-sans-serif;
$font-bold: "Frutiger LT W01_65 Bold1475746", sans-serif;
$font-roman: "Frutiger LT W01_55 Roma1475738", sans-serif;

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/fullpage.js/jquery.fullPage.scss";
@import "bower_components/slick-carousel/slick/slick.scss";
@import "bower_components/slick-carousel/slick/slick-theme.scss";
// endbower

// Global
::selection {
  background-color: $highlight-color;
  color: #fff;
}

body {
  font-weight: "45 Light";
  letter-spacing: 2px;
}

h1, h2 {
  font-weight: normal;
}

h1 {
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 2.9px;
  text-transform: uppercase;
  margin-bottom: .57142em;
  margin-top: 0.1em;
}

h2 {
  font-size: 24px;
  line-height: 27px;
  letter-spacing: 2.25px;
  font-family: $font-roman;
  margin-bottom: .1em;
  margin-top: 0.2em;
}

h3 {
  font-size: 1em;
  margin: 10px 0 5px 0;
  font-family: $font-bold;
  font-weight: normal;
}

b, strong {
  font-family: $font-bold;
  font-weight: normal;
}

p {
  margin-bottom: 20px;
  margin-top: 0;
}

dl {
  line-height: 20px;
  margin-bottom: 0;

  dt {
    margin-top: .5em;
    font-weight: normal;

    &:after {
      content: '.';
    }
  }

  dd {
    margin-bottom: .5em;
    font-weight: normal;
    font-family: $font-bold;
  }
}

// Header
header {
  display: block;
  background-color: $body-bg;
  padding: 25px 20px 18px 20px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  user-select: none;

  nav {
    float: right;
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      list-style: none;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 2.92px;
      margin-right: 34px;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        color: #000;

        &:hover, &:active, &.active {
          color: #000;
          text-decoration: none;
        }

        &.active, &:hover {
          border-bottom: 2px solid $highlight-color;
        }
      }
    }

    &:after {
      clear: right;
    }
  }

}

.fg {
  position: relative;
  z-index: 10;
}

// General Section-Layout
.section {
  background-color: #fff;

  a {
    color: $text-color;
    position: relative;
    border-bottom: 1px solid $highlight-color;

    &:hover {
      text-decoration: none;
      border-bottom: 0;
    }
  }

  .content-wrapper {
    @extend .fg;
    position: absolute;
    left: 0;

    width: 337px;
    background-color: rgba(255, 255, 255, 0.9);

    &.projekte {
    background-color: rgba(255, 255, 255, 0.95);
    }

    &.side {
      top: 0;
      bottom: 0;
      padding-top: 73px;

      .content:not(.top) {
        position: absolute;
        bottom: 0;
      }
    }

    &.full-bottom {
      bottom: 0;
      width: 100%;
    }

    .content {
      padding: 31px 26px 20px 21px;

      .multi-cols {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;

        .col {
          padding-right: 6em;
          max-width: 600px;
        }

        @media screen and (max-width: 1000px) {
          display: block;
          max-width: 816px;

          .col {
            display: block;
            max-width: none;
            padding-right: 0;
          }
        }
      }
    }

    &.corner {
      bottom: 0;
    }

  }

  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    &.dotted:after {
      content: ' ';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background-image: url('/assets/images/circles.svg');
    }

    &.dotted.big:after {
      background-size: 10px 10px;
    }

    .img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100vw;
      height: 100vh;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      opacity: 0;
      transition: opacity .2s linear;

      &.current {
        opacity: 1;
      }
    }
  }

  .bg.dotted ~ .bgNav {
    display: none;
  }
}

.slide {
  position: relative;
}

// Home
#pHome {

  .bg {
    top: 83px;
  }

  .claim {
    background-color: $body-bg;
    margin: 0;
    padding: (73px + 39px) 0 15px 17px;
    font-size: 36px;
    letter-spacing: 3px;
    line-height: 40px;
    text-transform: none;

    position: relative;
    z-index: 10;
  }

  .fp-tableCell {
    vertical-align: top;
  }
}

// Projekte
ul.projekte {
  list-style: none;

  padding: 0;
  margin: .5em 0;

  li {
    list-style: none;

    margin: 0;
    padding: 0;
    line-height: 24px;

    a {
      font-family: $font-roman;
      color: $text-color;
      border-bottom: 0;

      &:hover {
        border-bottom: 1px solid $highlight-color;
      }
    }
  }
}

// Leistung
.content.leistung {
  ul:not(.slick-dots) {
    list-style: none;
    padding: 0;

    li {
      list-style: none;
      font-family: $font-bold;
    }
  }

  @media screen and (max-width: 1000px) {
    ul:not(.slick-dots) {
      margin-bottom: 2em;

      li {
        display: inline;

        &:after {
          content: '·';
          margin-left: .5em;
        }

        &:last-of-type:after {
          content: normal;
        }
      }
    }
  }
}

// Responsivenezz *tchh*
@media screen and (min-width: 860px) {
  .mobileMenu {
    display: none;
  }

  #mainMenu {
    opacity: 1 !important;
  }
}

@media screen and (max-width: 860px) {

  h2 {
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 6.77px;
    font-family: $font-bold;
  }

  header {
    display: block;
    background-color: $body-bg;
    padding: 18px 20px 10px 20px;

    .logo img {
      height: 12px;
      width: auto;
    }
  }

  dl {
    dt, dd {
      margin-top: 0;
      margin-bottom: .5em;
      display: inline;
    }

    dt {
      margin-right: .5em;
      font-family: $font-bold;
    }

    dd {
      font-family: $font-roman;

      &:after {
        content: ' ';
        display: block;
      }
    }
  }

  .section {
    .content-wrapper {

      .content {
        padding: 20px;
      }

      &.side {
        width: 100%;

        .content {
          position: absolute;
          top: 53px;
          bottom: auto;
        }
      }

      &.corner {
        width: 100%;
      }

    }
  }

  .mobileMenu {
    display: inline-block;
    cursor: pointer;

    text-indent: -9999px;
    // Higher then the actual bg to make tapping
    // it easier.
    width: 30px;
    height: 30px;

    background-image: url('/assets/images/menu.svg');
    background-size: 17px 17px;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
    top: -5px;
    right: -5px;
  }

  #mainMenu {
    display: none;
  }

  #mainNav {
    &.toggled {

      .mobileMenu {
        background-image: url('/assets/images/menuClose.svg');
        background-size: 15px 15px;
      }

      #mainMenu {
        display: block;
        position: fixed;
        top: 53px;
        left: 0;
        bottom: 0;
        right: 0;

        opacity: 0;

        margin: 0;
        padding: 76px;
        text-align: center;

        background-color: rgba(255, 255, 255, 0.9);

        li {
          display: block;
          text-align: center;
          margin: 0;

          font-size: 22px;
          line-height: 60px;
          letter-spacing: 5.35px;
        }
      }

    }
  }

  #pHome {

    .bg {
      top: 53px;
    }

    .claim {
      font-size: 24px;
      letter-spacing: 2.25px;
      line-height: 30px;
      font-family: $font-normal;
      padding: (53px + 10px) 0 15px 17px;
    }

  }

}

// BG Indicators
.section .bgNav.bottom {
  left: auto;
  right: 20px;
  bottom: 15px;
  margin-left: auto !important;
  position: absolute;
  z-index: 4;
  opacity: 1;

  ul {
    margin: 0;
    padding: 0;
  }

  ul li {
    width: 12px;
    height: 12px;
    margin: 5px;
    display: inline-block;
    position: relative;

    a {
      display: block;
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      cursor: pointer;
      text-decoration: none;
      border-bottom: 0;
      outline: none;
    }

    a span {
      background: $text-color;
      height: 100%;
      width: 100%;
      margin: 0;

      border-radius: 50%;
      position: absolute;
      z-index: 1;
      border: 0;
      left: 50%;
      top: 50%;
      transition: all 0.1s ease-in-out;
    }

    a:hover span, a.active span {
      background: #fff;
      border: 2px solid $highlight-color;
    }
  }

  @media screen and (max-width: 860px) {
    display: none;
  }
}

// Slick
.slick-slide:focus {
  outline: none;
}

ul.slick-dots li button:before {
  font-size: 24px;
}


.main {
  padding-top: 73px;
}

.container--text {
  max-width: 620px;
  margin-left: 0;
  padding-left: 18px;
}
